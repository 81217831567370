.view--item {
  position: relative;
}
.view--item.view--home {
  margin-top: -65px;
  padding-top: 100px;
}

.view--item__content {
  padding: 1rem;
  /* padding-top: 100px; */
  /* max-width: 50rem; */
}

.view--item.view--home .view--item__content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
  height: calc(100% - 80px);
}

.view--item__content > p,
.view--item__content > ul,
.view--item__content > a {
  max-width: 50rem;
}

.view--item h1 {
  font-size: 9vw;
  text-transform: uppercase;
  color: #fff;
  font-weight: 1000;
  line-height: 0.875;
  margin-bottom: 0;
  letter-spacing: 0.05rem;
  /* margin-top: 5rem; */
}

.view--item .view--item__overline {
  font-size: 20px;
  margin-bottom: 0.25rem;
  margin-top: 1rem;
  letter-spacing: 0.035rem;
  opacity: 0.5;

  font-weight: 800;
  line-height: 1.1;
  display: block;
}
.view--item .view--item__subline {
  font-size: 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  letter-spacing: 0.035rem;

  font-weight: 800;
  line-height: 1.1;
  display: block;
}

.view--item .view--item__spacer {
  height: 1rem;
  display: block;
}

.view--item p {
  font-size: 18px;
  color: #27ad66;
  line-height: 1.5;
}

.view--item.view--home h1 {
  margin-bottom: 0;
  text-shadow: 0px 0px 20px #001b573f;
  margin-top: 0;
}

.view--item.view--home p {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.025rem;
  max-width: 90%;
  margin-top: 2rem;
  text-shadow: 0px 0px 20px #001b573f;
}

.view--item.view--home a,
.view--item.view--home a:link,
.view--item.view--home a:visited {
  color: #fff;
  font-weight: bolder;
  text-decoration: underline;
}

.view--item.view--home a:hover,
.view--item.view--home a:active,
.view--item.view--home a:focus {
  color: var(--neon-pink);
  text-decoration: none;
}

.view--item.view--home {
  background: url("/src/css-bg/hero.jpg") 50% 50%;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.view--item.view--cover {
  background-size: cover;
  width: 100vw;
  height: calc(100vh - 65px);
}

.view--item.view--hero {
  padding-top: 80px;
}
.view--item.view--hero h1,
.view--item.view--hero p {
  color: #fff;
}

.view--item.view--hero a,
.view--item.view--hero a:link,
.view--item.view--hero a:visited {
  color: #fff;
  text-decoration: underline;
}

.view--item.view--hero a:hover,
.view--item.view--hero a:active,
.view--item.view--hero a:focus {
  color: var(--neon-pink);
  text-decoration: none;
}

.view--item a,
.view--item a:link,
.view--item a:visited {
  transition: color 0.25s;
  cursor: pointer;
  text-decoration: underline;
}
.view--item a:hover,
.view--item a:active,
.view--item a:focus {
  text-decoration: none;
}

.view--item__referenzen {
  list-style: none;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.view--item__referenzen-item {
  margin: 1rem;
  opacity: 0.85;
}

@media only screen and (min-width: 670px) {
  .view--item__content {
    padding: 2rem 4rem 1rem 3rem;
  }
  .view--item h1 {
    /* margin-top: 6rem; */
    font-size: 72px;
  }
  .view--item.view--colors-dark h1,
  .view--item.view--colors-medium h1,
  .view--item.view--colors-light h1 {
    font-size: 50px;
  }
  .view--item.view--cover h1 {
    /* margin-top: 5rem; */
    font-size: 72px;
  }
  .view--item h1 .view--item__overline {
    font-size: 20px;
  }
  .view--item h1 .view--item__subline {
    font-size: 20px;
  }

  .view--item .view--item__spacer {
    height: 2rem;
    display: block;
  }
  .view--item p {
    font-size: 20px;
  }
  .view--item.view--home {
    background: url("/src/css-bg/hero.jpg") 90% 50%;
    background-size: cover;
  }

  .view--item.view--home p {
    font-size: 20px;

    max-width: 30rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .view--item.view--cover {
    height: 100vh;
    /* min-height: 500px */
  }
}

@media only screen and (min-width: 821px) {
  .view--item.view--home .view--item__content {
    display: block;
  }
}

@media only screen and (min-width: 1028px) {
  .view--item__content {
    padding: 4rem 6rem 2rem 6rem;
  }
  .view--item.view--home {
    background: url("/src/css-bg/hero.jpg") 50% 50%;
    background-size: cover;
  }
  .view--item h1 {
    /* margin-top: 5rem; */
    font-size: 72px;
  }
  .view--item.view--colors-dark h1,
  .view--item.view--colors-medium h1,
  .view--item.view--colors-light h1 {
    font-size: 50px;
  }
  .view--item.view--cover h1 {
    /* margin-top: 5rem; */
    font-size: 72px;
  }
  .navigation {
    width: 50vw;
    transform: translateX(50vw);
  }
}
/** cover **/
.view--item.view--cover {
  background-position: 90% 50%;
}
.view--item.view--cover .view--item__content {
  height: 100%;
}

.view--item__home-teaser {
  padding-top: 2rem;
  padding-bottom: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: nowrap;
  height: 100%;
}
.view--item.view--cover h1 {
  max-width: 90%;
}

.view--item.view--cover p {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.025rem;
  max-width: 90%;
  margin-top: 2rem;
}

.view--item.view--cover {
  background-position: 50% 50%;
}

@media only screen and (min-width: 670px) {
  .view--item.view--cover h1 {
    max-width: 40rem;
  }

  .view--item__home-teaser {
    padding-bottom: 2rem;
  }

  .view--item.view--cover p {
    font-size: 20px;

    max-width: 30rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
}

@media only screen and (min-width: 1028px) {
  .view--item.view--cover h1 {
    max-width: 40rem;
  }

  .view--item__home-teaser {
    padding-bottom: 2rem;
  }

  .view--item.view--cover p {
    max-width: 30rem;
  }
  .view--item.view--cover {
    background-position: 50% 50%;
  }
}
