form {
  max-width: 35rem;
  margin: 1em 0;
}
input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--neon-pink);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 0.5rem;
  resize: vertical;
  margin: 0.25rem 0 1.5em 0;
  font-size: 1rem;
}
input[type="text"]:hover,
input[type="text"]:focus,
input[type="text"]:active,
input[type="email"]:hover,
input[type="email"]:focus,
input[type="email"]:active,
select:hover,
select:focus,
select:active,
textarea:hover,
textarea:focus,
textarea:active {
  border: 1px solid var(--neon-pink);
}

.contact__address {
  font-size: 20px;
  font-style: normal;
}
