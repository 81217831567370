* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", "Noto Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #ffffff;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", "Noto Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  hyphens: manual;
  max-width: 90vw;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.navigation--list__item,
.navigation-lang--button {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

[lang="gr"] body,
[lang="gr"] h1,
[lang="gr"] h2,
[lang="gr"] h3,
[lang="gr"] h4,
[lang="gr"] h5,
[lang="gr"] h6,
.navigation--list__item,
.navigation-lang--button {
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  hyphens: manual;
  max-width: 90vw;
}

h1 {
  margin-top: 0;
}
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  margin-top: 0;
}
a {
  transition: background 0.25ms ease-in;
}
