.view--hero {
  background: var(--neon-blue);
}
.view--bg-100 {
  background: var(--shade-100);
}
.view--bg-200 {
  background: var(--shade-200);
}
.view--bg-300 {
  background: var(--shade-300);
}
.view--bg-400 {
  background: var(--shade-400);
}
.view--bg-500 {
  background: var(--shade-500);
}
.view--bg-600 {
  background: var(--shade-600);
}
.view--bg-700 {
  background: var(--shade-700);
}
.view--bg-800 {
  background: var(--shade-800);
}
.view--bg-900 {
  background: var(--shade-900);
}
.view--bg-950 {
  background: var(--shade-950);
}

.view--colors-light h1 {
  color: var(--neon-blue);
}
.view--colors-light h2 {
  color: var(--neon-pink);
}
.view--colors-light p {
  color: var(--shade-50);
}

.view--item__home-teaser h1 a {
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
}
.view--item__home-teaser h1 a svg {
  margin-right: 10px;
}
.view--item__home-teaser a,
.view--item__home-teaser a:link,
.view--item__home-teaser a:visited {
  color: var(--shade-200);
  text-decoration: none;
}

.view--item__home-teaser a:hover,
.view--item__home-teaser a:active,
.view--item__home-teaser a:focus {
  color: white;
}

.view--colors-light a,
.view--colors-light a:link,
.view--colors-light a:visited {
  color: var(--shade-50);
}

.view--colors-light a:hover,
.view--colors-light a:active,
.view--colors-light a:focus {
  color: var(--neon-pink);
}

.view--colors-medium h1 {
  color: var(--shade-200);
}
.view--colors-medium p {
  color: var(--shade-100);
}

.view--colors-medium a,
.view--colors-medium a:link,
.view--colors-medium a:visited {
  color: var(--shade-200);
}

.view--colors-medium a:hover,
.view--colors-medium a:active,
.view--colors-medium a:focus {
  color: var(--neon-pink);
}

.view--colors-dark h1 {
  color: var(--neon-pink);
}

.view--colors-dark p {
  color: var(--shade-700);
}

.view--colors-dark a,
.view--colors-dark a:link,
.view--colors-dark a:visited {
  color: var(--shade-700);
}

.view--colors-dark a:hover,
.view--colors-dark a:active,
.view--colors-dark a:focus {
  color: var(--neon-pink);
}
