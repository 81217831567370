:root {
  --neon-blue: #04d9ff;
  --neon-orange: #ffa703;
  --neon-pink: #ff035b;

  --shade-50: #f0f9ff;
  --shade-100: #e0f2fe;
  --shade-200: #bae6fd;
  --shade-300: #7dd3fc;
  --shade-400: #38bdf8;
  --shade-500: #0ea5e9;
  --shade-600: #0284c7;
  --shade-700: #0369a1;
  --shade-800: #075985;
  --shade-900: #0c4a6e;
  --shade-950: #082f49;

  /* --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;
  --blue-950: #172554; */
}
