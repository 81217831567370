.header {
  height: 65px;
  background: #ffffff;
  display: block;
  margin: 0;
  padding: 12px 2rem 8px 2rem;
  position: relative;
  z-index: 1;
}

.header--logo {
  height: 100%;
  width: 225px;
}
