.footer {
  padding: 2rem 1rem 3rem 1rem;

  display: block;
  margin: 0;
  font-size: 12px;
  color: var(--shade-300);
  font-size: 14px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  align-items: flex-start;
}

.footer--logo {
  height: 50px;
}

.footer--address {
  font-style: normal;
  margin: 1rem 0 0;
}

.footer a svg {
  margin-right: 5px;
}
.footer a,
.footer a:link,
.footer a:visited {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: color 0.25s;
  cursor: pointer;
  color: var(--shade-300);
  /* font-weight: bold; */
  text-decoration: underline;
}

.footer a:hover,
.footer a:active,
.footer a:focus {
  color: var(--neon-blue);
  text-decoration: none;
}

@media only screen and (min-width: 670px) {
  .footer {
    flex-flow: row;
    align-items: flex-end;
    padding: 1.5rem 4rem 2.5rem 3rem;
  }
  .footer--address {
    margin: 0 2rem -0.25rem 2rem;
  }
}

@media only screen and (min-width: 1028px) {
  .footer--address {
    font-style: normal;
    margin: 0 2rem -0.25rem 2rem;
  }
  .footer {
    align-items: flex-end;
    padding: 1.5rem 6rem 2.75rem 6rem;
  }
}
