.button-group {
  display: flex;

  margin-top: 2rem;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.button--cta {
  background: var(--neon-pink);
  display: inline-block;
  color: #fff;
  margin: 0;
  font-weight: 800;
  position: relative;
  text-decoration: none;
  font-size: 1rem;
  border: none;
  cursor: pointer;
}

.button--cta::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  content: "";
  background-color: var(--neon-blue);
  z-index: 1;
  transition: width 0.25s ease-in;
  cursor: pointer;
}

.button--cta:hover::after {
  width: 100%;
}

.button--cta .button--cta__text {
  padding: 0.75rem 1rem;
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.button--scroll-down {
  bottom: 0.5rem;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  left: 50%;
  margin-left: -55px;
  transition: all 0.25s ease-in;
}

.button--scroll-down .button--scroll-down__text {
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: block;
}

.button--scroll__down:hover {
  bottom: 0.25rem;
}

.button--scroll-down .button--scroll-down__icon {
  margin-top: -5px;
  width: 30px;
  height: 30px;
  transform: rotate(-90deg);
}

.button--scroll__down:hover::after {
  width: 100%;
}

.button--scroll-down a,
.button--scroll-down a:link,
.button--scroll-down a:visited,
.button--scroll-down a:hover,
.button--scroll-down a:active,
.button--scroll-down a:focus {
  padding: 1rem 1rem;
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: inline-block;
  text-decoration: none;
  color: #fff;
}
