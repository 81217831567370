.navigation {
  height: 90px;
  background: #f00;
  display: block;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

.navigation--button {
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: 8px 0 0 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  border: none;
  outline: 0;
}

.navigation--button:active,
.navigation--button:focus {
  outline: 0;
}

.navigation--button__svg {
  cursor: pointer;
  height: 100%;
  width: 100%;
  opacity: 0.75;
  transition: opacity 0.25s, transform 0.25s;
}

.navigation--button__svg:hover,
.navigation--button__svg:active,
.navigation--button__svg:focus {
  opacity: 1;
  transform: scale(0.9);
}

.navigation--button__open {
}
.navigation--button__close {
  position: absolute;
  right: 2.3rem;
  top: 0.7rem;
  z-index: 102;
}

.navigation {
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  /* padding: 20px 2rem; */
  padding: 3rem 3rem;
}

.navigation--list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation--list__item {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;

  text-decoration: none;
  margin: 0 0 1.25rem 0;
  transition: color 0.25s;
}

.navigation--list__item > * {
  transition: color 0.25s;
}

.navigation--list__item > :link,
.navigation--list__item > :visited {
  color: var(--neon-blue);
  text-decoration: none;
}

.navigation--list__item > :hover,
.navigation--list__item > :active,
.navigation--list__item > :focus {
  color: var(--neon-pink);
}

.navigation--list__item > .active {
  color: var(--neon-orange);
  border-bottom: 1px solid var(--neon-orange);
}

.navigation-lang {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 30px;
  margin: 8px 4px 0 0;
  width: auto;
}

.navigation-lang--button {
  font-weight: bold;
  appearance: none;
  border: none;
  background: transparent;
  margin: 0 10px 0 0;
  padding: 0 0;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--neon-blue);
}

.navigation-lang--button {
  transition: color 0.25s;
}

.navigation-lang--button:link,
.navigation-lang--button:visited {
  color: var(--neon-blue);
  text-decoration: none;
}

.navigation-lang--button.active {
  color: var(--neon-orange);
  border-bottom: 1px solid var(--neon-orange);
}

.header .navigation-lang--button.active {
  color: var(--neon-orange);
  border-bottom: none;
}

.navigation-lang--button:hover,
.navigation-lang--button:active,
.navigation-lang--button:focus {
  color: var(--neon-pink);
}

@media only screen and (min-width: 670px) {
  .navigation {
    width: 66vw;
    transform: translateX(66vw);
  }
}

@media only screen and (min-width: 1028px) {
  .navigation {
    width: 50vw;
    transform: translateX(50vw);
  }
}
